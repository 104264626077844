.slick-next {
  right: -25px !important;
  @media (max-width: 1366px) {
    right: -7px !important;
  }
}

@media only screen and (max-width: 1200px) {
  nav ul {
    float: left;
    margin-right: 20px;
    padding: 0;
    width: 260px;
    height: 100%;
    display: flex;
    /* padding: 20px 0px; */
    // background: #000;
    align-items: flex-start;
    padding-left: 10px;
    // flex-direction: column;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1024px) {
  nav ul {
    float: left;
    margin-right: 20px;
    padding: 0;
    width: 260px;
    height: 100%;
    display: flex;
    /* padding: 20px 0px; */
    // background: #000;
    align-items: flex-start;
    padding-left: 10px;
    // flex-direction: column;
    justify-content: flex-start;
  }

  .roadmap-step {
    border: 1px solid transparent;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    position: relative;
    margin-left: 50px;
    padding: 20px 20px 15px;
    position: relative;
    max-width: 352px;
  }
}

@media (min-width: 992px) {
  .roadmap-left .roadmap-step:before {
    left: auto;
    right: -84px;
  }
  .roadmap-left .roadmap-step:after {
    left: auto;
    right: -183px;
  }
  .roadmap-step:after {
    width: 73px;
    left: -184px;
  }
  .roadmap-left .roadmap-step {
    margin-left: 0;
    margin-right: 80px;
    text-align: right;
  }
  .roadmap-left {
    justify-content: flex-start;
    margin-top: -80px;
    margin-left: 0;
    text-align: right;
  }
  .roadmap-right .roadmap-step {
    margin-left: 40px;
  }
  .roadmap-head {
    margin-bottom: 15px;
  }
  .roadmap-wrap {
    padding: 81px 0 10px;
    margin-bottom: 60px;
    max-width: 100%;
  }

  .roadmap-line {
    left: 50%;
    top: 6px;
    margin-left: -1px;
  }
  .roadmap {
    display: flex;
  }

  .roadmap-year {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 16px;
    position: static;
    margin-left: auto;
    margin-right: auto;
  }

  .roadmap-right {
    justify-content: flex-end;
    margin-top: -108px;
  }

  .roadmap-right .roadmap-step {
    margin-left: 80px;
  }

  .roadmap-left {
    justify-content: flex-start;
    margin-top: -80px;
    text-align: right;
  }

  .roadmap-left .roadmap-step {
    margin-left: 0;
    margin-right: 80px;
  }
  .roadmap-left .roadmap-step:before {
    right: -218px;
  }

  .roadmap-head {
    margin-bottom: 15px;
  }

  .roadmap-step {
    width: 100%;
    padding: 20px 30px 35px;
  }
  .roadmap-step:before {
    left: -216px;
  }
}
@media (min-width: 1280px) and (max-width: 1280px) {
  .roadmap-left .roadmap-step:after {
    right: -73px;
  }

  .roadmap-step:after {
    width: 73px;
    left: -73px;
  }

  .roadmap-step:before {
    left: -84px;
  }

  .roadmap-left .roadmap-step:before {
    right: -84px;
  }
}

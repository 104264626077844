@charset "UTF-8";
:root {
  font-size: 14px;
  overflow-x: hidden;
}

.height100 {
  min-height: 100%;
}

.scroll ::-webkit-scrollbar {
  display: none;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.displaySpacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textGradient {
  background: var(--fg, linear-gradient(90deg, #39a9f4 0%, #62d3f0 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.showScroll ::-webkit-scrollbar {
  display: block;
  background-color: #142829;
  width: 8px;
}
.showScroll ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.136);
  border-radius: 20px;
}

.scroll1 {
  height: calc(100vh - 245px);
}
.scroll1 ::-webkit-scrollbar {
  display: none;
}

.scroll2 {
  height: calc(100vh - 480px);
}
.scroll2 ::-webkit-scrollbar {
  display: none;
}

.carousel-dots li.carousel-dots-active button:before {
  opacity: 1 !important;
  color: #029cdc !important;
}

.carousel-dots li button:before {
  font-family: "slick";
  font-size: 15px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1;
  color: #d9d9d9 !important;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
}

.hexa {
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  -moz-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  -ms-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.roadmap-right .roadmap-step {
  margin-left: 40px;
}

.roadmap-left {
  justify-content: flex-start;
  margin-left: 40px;
}

.roadmap-left .roadmap-step {
  margin-left: 0;
  margin-right: 0px;
}

.roadmap-left .roadmap-step:before {
  right: -36px;
}

.roadmap-head {
  margin-bottom: 15px;
}

.roadmap-step {
  width: auto;
  padding: 20px 30px 20px;
}

.roadmap-step:before {
  left: -36px;
}

.roadmap-wrap {
  position: relative;
  margin-bottom: 40px;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.roadmap-wrap:before,
.roadmap-wrap:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: 7;
}

.roadmap-line {
  position: absolute;
  left: 19px;
  top: 6;
  /* background: #c1cef1; */
  height: 92%;
  /* width: 2px; */
  content: "";
  z-index: 9;
  border: 2px dashed #029cdc;
}

.roadmap-line:before {
  top: 6;
}

.roadmap-line:after {
  bottom: 0;
}

.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.roadmap-year {
  border-radius: 50%;
  color: #000;
  background: #eab73b;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial";
  font-size: 12px;
  position: relative;
  left: -5px;
}

.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.bg-light .roadmap-step {
  background: #fff;
}

.roadmap-step {
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  position: relative;
  margin-left: 50px;
  padding: 20px 20px 15px;
  position: relative;
  max-width: 380px;
}
@media (max-width: 768px) {
  .roadmap-step {
    border-radius: 16px;
  }
}

.bg-light .roadmap-step:before {
  border-color: #f6fafd;
}

.roadmap-step:before {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 8px solid rgb(2, 156, 220);
  background: #fff;
  left: -36px;
  top: 29px;
  box-shadow: 0px 4px 4px #afe8ff, 0px 0px 22px 2px #77d7ff;
  z-index: 1;
}
@media (max-width: 768px) {
  .roadmap-step:before {
    height: 12px;
    width: 12px;
  }
}

.roadmap-step:before,
.roadmap-step:after {
  position: absolute;
  content: "";
}

.roadmap-head {
  margin-bottom: 10px;
  padding-right: 35px;
  position: relative;
}

.jodit-container:not(.jodit_inline) {
  border: "1px solid #029CDC";
}

.roadmap-time {
  display: block;
  color: #122272;
  font-size: 13px;
  text-transform: uppercase;
}

.roadmap-title {
  color: #8ca2e5;
  font-weight: 700;
  font-size: 16px;
}

.bg-light .roadmap-step:before {
  border-color: #029cdc;
}

.roadmap-step p:last-child {
  margin-bottom: 0;
}

.roadmap-step:after {
  width: 36px;
  border: 1px dashed #029cdc;
  left: -22px;
  top: 42px;
}

.roadmap-step:before,
.roadmap-step:after {
  position: absolute;
  content: "";
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.slick-prev:before {
  font-size: 32px;
  margin: -9px;
  color: #029cdc;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 768px) {
  .slick-prev:before {
    font-size: 25px;
  }
}

.slick-prev {
  left: -28px;
  top: 105px;
}
@media (max-width: 768px) {
  .slick-prev {
    left: -18px;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 36px;
  line-height: 1;
  opacity: 1;
  color: #029cdc;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 768px) {
  .slick-prev:before,
  .slick-next:before {
    font-size: 25px;
  }
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #47bcf2;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: "0px !important";
}

.carousel-dots li button:before {
  font-family: "slick";
  font-size: 12px !important;
  line-height: 20px;
  position: absolute;
  content: "•" !important;
  opacity: 2 !important;
  text-align: center;
  color: #d9d9d9 !important;
}

.carousel-dots li.carousel-dots-active button:before {
  opacity: 1 !important;
  color: #029cdc !important;
}

.MuiPaper-rounded {
  border-radius: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.chatboxRace {
  height: 100%;
  border-radius: 17.546px;
  border: 0.964px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(144.6744995117px);
          backdrop-filter: blur(144.6744995117px);
  overflow: hidden;
  position: relative;
}

.sliderbox .slick-slide div {
  height: 100px;
}

.react-multi-carousel-item {
  width: auto !important;
}

@media (max-width: 599.9px) {
  .Logoimg {
    width: 130px !important;
    padding-top: 0px !important;
  }
  .react-multi-carousel-list {
    max-width: 400px !important;
  }
}
@media (max-width: 393px) {
  .loginlogoImage {
    width: 50px !important;
  }
  .react-multi-carousel-list {
    max-width: auto !important;
  }
}
.carousel-container {
  display: flex !important;
  margin-left: -34px;
}

.react-multi-carousel-track {
  display: flex !important;
}

.slickbottomslider {
  width: 100%;
  position: relative;
}
.slickbottomslider .slick-initialized .slick-slide.slick-current .active {
  transform: scale(1.15);
  transition: all 0.4s ease-in;
}
.slickbottomslider .slick-next:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -14px !important;
}
.slickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -23px !important;
}
.slickbottomslider .slick-track {
  padding: 60px 0;
}
.slickbottomslider .slick-prev:before {
  content: "‹";
}
.slickbottomslider .slick-next:before {
  content: "›";
}
.slickbottomslider .slick-list {
  padding-left: 0px !important;
}
.slickbottomslider .slick-prev {
  left: auto !important;
  top: -25px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: #2196f3 !important;
  width: 33px;
  height: 33px;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .slickbottomslider .slick-prev {
    top: 21px !important;
  }
}
.slickbottomslider .slick-next {
  top: -25px !important;
  left: auto !important;
  right: 5px !important;
  color: #fff !important;
  background-color: #2196f3 !important;
  z-index: 999 !important;
  width: 33px;
  height: 33px;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .slickbottomslider .slick-next {
    top: 21px !important;
  }
}
.slickbottomslider .slick-next:before,
.slickbottomslider .slick-prev:before {
  font-size: 37px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
  margin-top: -17px;
}

.slickbottomsliderEvent .slick-prev {
  top: 50% !important;
  transform: translateY(-50%);
  left: -9px !important;
  right: auto !important;
}
.slickbottomsliderEvent .slick-next {
  top: 50% !important;
  transform: translateY(-50%);
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 18px !important;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgb(255, 212, 129) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid rgb(255, 212, 129);
  border-radius: 50px;
  width: 21px !important;
  height: 21px !important;
  line-height: 23px !important;
}

.slick-dots {
  bottom: -27px !important;
  z-index: 999;
}
@media (max-width: 1556px) {
  .slick-dots {
    bottom: -38px !important;
  }
}

.slickbottomslider .slick-track {
  padding: 23px 0 23px;
}

.socildrawerBox {
  position: absolute !important;
  bottom: 23px !important;
  display: flex;
  padding: 10px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2509803922);
}
.socildrawerBox svg {
  color: #41b1f3;
  font-size: 18px;
}

.even-row {
  background-color: #f4f4f4;
  /* Define your desired background color for even rows */
}

.odd-row {
  background-color: #ffffff;
  /* Define your desired background color for odd rows */
}

.displayCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socionIconImg {
  background: transparent !important;
}

.shareNowButton {
  width: calc(100% - 115px);
}

.slickcenterslider .slick-prev {
  top: 45% !important;
  left: 0 !important;
}

.slickcenterslider .slick-next {
  top: 45% !important;
  right: 0 !important;
}

.MuiDialog-paperWidthLg {
  max-width: 500px !important;
  position: absolute !important;
  top: 75px !important;
  right: 20% !important;
}
.MuiDialog-paperWidthLg p {
  margin-bottom: 0px;
}

.slickbottomslider .slick-disabled {
  background-color: #ccc;
}

.slickbottomslider .slick-prev.slick-disabled {
  opacity: 0.5;
}

.slickbottomslider .slick-next.slick-disabled {
  opacity: 0.5;
}

.item:nth-child(1) {
  order: 1;
  /* This item will appear third visually */
}

.item:nth-child(2) {
  order: 2;
  /* This item will appear first visually */
}

.tabbottomText {
  color: #383838;
}

.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 767px) {
  .mainTab {
    overflow: auto;
  }
}

.buttonSlider .slick-prev {
  top: 181px !important;
}

.buttonSlider .slick-next {
  top: 181px !important;
}

.tabActiveButtons {
  background: linear-gradient(90deg, #39a9f4 8.33%, #62d3f0 100%);
  padding: 11px 40px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
}
@media (max-width: 1021px) {
  .tabActiveButtons {
    padding: 11px 25px;
  }
}
@media (max-width: 865px) {
  .tabActiveButtons {
    padding: 11px 16px;
  }
}
@media (max-width: 639px) {
  .tabActiveButtons {
    padding: 11px 8px;
  }
}

.tabButtons {
  padding: 11px 40px;
  white-space: pre;
  cursor: pointer;
  color: #858585;
}
@media (max-width: 1021px) {
  .tabButtons {
    padding: 11px 25px;
  }
}
@media (max-width: 865px) {
  .tabButtons {
    padding: 11px 16px;
  }
}
@media (max-width: 639px) {
  .tabButtons {
    padding: 11px 8px;
  }
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: start;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.displaySpacearound {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #2b91ef;
  border-radius: 50px;
  width: -moz-fit-content;
  width: fit-content;
}
@media (max-width: 767px) {
  .mainTab {
    overflow: auto;
  }
}

.tabActiveButtons {
  background: #2b91ef;
  border-radius: 50px;
  padding: 8px 30px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
}
@media (max-width: 1021px) {
  .tabActiveButtons {
    padding: 8px 30px;
  }
}
@media (max-width: 865px) {
  .tabActiveButtons {
    padding: 8px 30px;
  }
}
@media (max-width: 639px) {
  .tabActiveButtons {
    padding: 8px 30px;
  }
}

.tabButtons {
  border-radius: 50px;
  padding: 8px 30px;
  white-space: pre;
  cursor: pointer;
  color: #2b91ef;
}
@media (max-width: 1021px) {
  .tabButtons {
    padding: 8px 30px;
  }
}
@media (max-width: 865px) {
  .tabButtons {
    padding: 8px 30px;
  }
}
@media (max-width: 639px) {
  .tabButtons {
    padding: 8px 30px;
  }
}

.blinking-text-container {
  position: relative;
}

.blinking-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: yellow;
  padding: 5px;
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contentBoxNew {
  background: #f3f3f3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  padding: 5px 15px;
  border-radius: 12px;
  margin-bottom: 8px;
}
.contentBoxNew h6,
.contentBoxNew p {
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 500;
}

.flexwrap {
  flex-wrap: wrap;
}

.slick-next {
  right: -25px !important;
}
@media (max-width: 1366px) {
  .slick-next {
    right: -7px !important;
  }
}

@media only screen and (max-width: 1200px) {
  nav ul {
    float: left;
    margin-right: 20px;
    padding: 0;
    width: 260px;
    height: 100%;
    display: flex;
    /* padding: 20px 0px; */
    align-items: flex-start;
    padding-left: 10px;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1024px) {
  nav ul {
    float: left;
    margin-right: 20px;
    padding: 0;
    width: 260px;
    height: 100%;
    display: flex;
    /* padding: 20px 0px; */
    align-items: flex-start;
    padding-left: 10px;
    justify-content: flex-start;
  }
  .roadmap-step {
    border: 1px solid transparent;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    position: relative;
    margin-left: 50px;
    padding: 20px 20px 15px;
    position: relative;
    max-width: 352px;
  }
}
@media (min-width: 992px) {
  .roadmap-left .roadmap-step:before {
    left: auto;
    right: -84px;
  }
  .roadmap-left .roadmap-step:after {
    left: auto;
    right: -183px;
  }
  .roadmap-step:after {
    width: 73px;
    left: -184px;
  }
  .roadmap-left .roadmap-step {
    margin-left: 0;
    margin-right: 80px;
    text-align: right;
  }
  .roadmap-left {
    justify-content: flex-start;
    margin-top: -80px;
    margin-left: 0;
    text-align: right;
  }
  .roadmap-right .roadmap-step {
    margin-left: 40px;
  }
  .roadmap-head {
    margin-bottom: 15px;
  }
  .roadmap-wrap {
    padding: 81px 0 10px;
    margin-bottom: 60px;
    max-width: 100%;
  }
  .roadmap-line {
    left: 50%;
    top: 6px;
    margin-left: -1px;
  }
  .roadmap {
    display: flex;
  }
  .roadmap-year {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 16px;
    position: static;
    margin-left: auto;
    margin-right: auto;
  }
  .roadmap-right {
    justify-content: flex-end;
    margin-top: -108px;
  }
  .roadmap-right .roadmap-step {
    margin-left: 80px;
  }
  .roadmap-left {
    justify-content: flex-start;
    margin-top: -80px;
    text-align: right;
  }
  .roadmap-left .roadmap-step {
    margin-left: 0;
    margin-right: 80px;
  }
  .roadmap-left .roadmap-step:before {
    right: -218px;
  }
  .roadmap-head {
    margin-bottom: 15px;
  }
  .roadmap-step {
    width: 100%;
    padding: 20px 30px 35px;
  }
  .roadmap-step:before {
    left: -216px;
  }
}
@media (min-width: 1280px) and (max-width: 1280px) {
  .roadmap-left .roadmap-step:after {
    right: -73px;
  }
  .roadmap-step:after {
    width: 73px;
    left: -73px;
  }
  .roadmap-step:before {
    left: -84px;
  }
  .roadmap-left .roadmap-step:before {
    right: -84px;
  }
}
@media (max-width: 1280px) {
  .MuiDialog-paperWidthLg {
    right: 11% !important;
  }
  .logoresponsive {
    margin-left: 10px;
  }
}
@media (max-width: 1024px) {
  .logoresponsive {
    margin-left: 10px;
  }
}
@media (max-width: 767px) {
  .noFlex {
    display: block !important;
  }
  .tabbottomText {
    font-size: 20px !important;
  }
  .rightSideBox {
    flex-wrap: wrap;
  }
  .item:nth-child(1) {
    order: 2; /* This item will appear third visually */
  }
  .item:nth-child(2) {
    order: 1; /* This item will appear first visually */
  }
  .MuiDialog-paperWidthLg {
    right: 0% !important;
    width: calc(100% - 38px) !important;
    margin: 16px !important;
  }
  .MuiDialog-paperWidthLg p {
    font-size: 12px !important;
    margin-bottom: 0px;
  }
  .eventscrollTab {
    min-width: 80px;
    overflow: auto;
  }
  .shareNowButton {
    width: 100%;
  }
  .MuiTableCell-root {
    font-size: 11px !important;
    line-height: 22px;
  }
  .slickbottomslider .slick-track {
    padding: 40px 0 23px;
  }
  .roadmap {
    padding-bottom: 0px;
  }
  .roadmap-step {
    padding: 18px 0px 0px 20px;
  }
  .logoresponsive {
    width: 60px !important;
    margin-left: 10px;
  }
}/*# sourceMappingURL=main.css.map */
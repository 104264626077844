@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  .MuiDialog-paperWidthLg {
    right: 11% !important;
}
  .logoresponsive {
    margin-left: 10px;
  }
}
@media (max-width: 1024px) {
  .logoresponsive {
    margin-left: 10px;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
  .noFlex{display: block !important;}
  .tabbottomText {
    font-size: 20px !important;
  }
  .rightSideBox {
    flex-wrap: wrap;
  }

.item:nth-child(1) {
  order: 2; /* This item will appear third visually */
}

.item:nth-child(2) {
  order: 1; /* This item will appear first visually */
}
  .MuiDialog-paperWidthLg {
    right: 0% !important;
    width: calc(100% - 38px) !important;
    margin: 16px !important;
    p{
      font-size: 12px !important;
      margin-bottom: 0px;
    }
}
  .eventscrollTab {
    min-width: 80px;
    overflow: auto;
  }
  .shareNowButton {
    width: 100%;
  }
  .MuiTableCell-root {
    font-size: 11px !important;
    line-height: 22px;
  }
  .slickbottomslider .slick-track {
    padding: 40px 0 23px;
  }
  .roadmap {
    padding-bottom: 0px;
  }
  .roadmap-step {
    padding: 18px 0px 0px 20px;
  }
  .logoresponsive {
    width: 60px !important;
    margin-left: 10px;
  }
  // .slickbottomslider .slick-prev {
  //   top: 5px !important;
  // }
  // .slickbottomslider .slick-next {
  //   top: 5px !important;
  // }
}

@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
}
